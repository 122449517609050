.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #282c34;
  margin-top: 20px;
}

.calculator {
  display: block;
  max-width: 150px;
}

.MuiFormControl-root.MuiFormControl-root {
  margin-right: 10px;
  margin-top: 20px;
}
.softox-logo {
  display: block;
}

.MuiCardHeader-root.MuiCardHeader-root,
h3 {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.MuiPaper-root.MuiPaper-root {
  width: 100%;
  min-width: 100%;
}
.basic-info.basic-info {
  background-color: #fdfcd5;
}

.calculation.calculation {
  background-color: #e4fdf5;
}

.App-link {
  color: #61dafb;
}

.calculated-numbers {
  display: inline-block;
  text-align: left;
  margin: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.MuiCardHeader-title.MuiCardHeader-title {
  font-weight: bold;
}
.subtitle.subtitle.subtitle.subtitle.subtitle .MuiCardHeader-title {
  font-weight: normal;
}

.calculated-numbers .number {
  display: block;
  font-size: 36px;
  font-weight: bold;
}

.calculated-numbers .number.green {
  color: #178a08;
}

.stock-posession {
  background: #fff;
}

.calculated-numbers .label {
  display: block;
  margin-top: -7px;
  font-size: 12px;
}

.credit {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 20px;
  color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
